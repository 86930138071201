import isDev from "../components/helpers/isDev";
import { getQueryParam } from "../components/helpers/utils.service";

export const constantData = {
  contactTypes: [
    {
      name: "יחץ מקור",
      contacts: [
        {
          name: "דבורי",
          role: null,
          email: "dvory@mfu.co.il",
          phone: null,
        },
        {
          name: "אבינועם",
          role: null,
          email: "avinoam@minkovsky-media.com",
          phone: null,
        }
      ],
    },
    {
      name: "יחץ רכש",
      contacts: [
        {
          name: "לי",
          role: null,
          email: "lee.nadel@hot.net.il",
          phone: null,
        },
        {
          name: "ליאור",
          role: null,
          email: "lior.eden@hot.net.il",
          phone: null,
        }
      ],
    },
  ],
};

const DEFAULT_SITE = "hot";
const DEVELOPING_LOCALHOST_SITE = "hot8";

const SITES = [
  {
    slug: "hot",
    name: "HOT",
    organization_id: "5df35d71c8975a58efc6a1aa",
    releaseTypes: {
      show: "612db12b8ad9893533b85bae",
      monthlyBoard: "612db1618ad9893533b85baf",
    },
    hostnames: ["hot-pr.megaphon.co.il"],
    pathnames: ['/hot-app/hot/', '/hot-app/hot'],
    socialLinks: {
      facebook: "https://www.facebook.com/hot.net.il",
      instagram: "https://www.instagram.com/hot_il/",
      twitter: "https://twitter.com/hotmobileisrael?lang=he",
      youtube: "https://www.youtube.com/user/hot",
    },
    logos: [
      "hot_comedy_centra_square.jpg",
      "hot_doco_reality_square.png",
      "hot_vod_movies_square.jpg",
      "hot_vod_square.jpg",
      "hot_vod_young_square.jpg",
      "hot_zone_square.jpg",
      "HOT3.JPG",
      "בידור.jpg",
      // 'hot_hbo_flach.png'
    ],
  },
  {
    slug: "hot8",
    name: "HOT 8",
    organization_id: "61bee2623df1d353c6158e22",
    releaseTypes: {
      show: "61c3631b403f3a8a0e4fac73",
      monthlyBoard: "61c364e90efe19fb4b135eb2",
    },
    hostnames: ["hot8.megaphon.co.il"],
    pathnames: ['/hot-app/hot8/', '/hot-app/hot8'],
    socialLinks: {
      facebook: "https://www.facebook.com/HOT8/",
      instagram: "https://www.instagram.com/channel_8/",
      youtube: "https://www.youtube.com/channel/UCox5OAXbuivHAI9j8NH4Pyw",
    },
    logos: ["hscc.png", "hot8-clogo-new.png"],
  },
];

const siteNameInQuery = getQueryParam('site');
const siteNameInStorage = localStorage.siteName;
const requiredApp = SITES.find(c => (c.slug === siteNameInQuery) || (c.slug === siteNameInStorage));
if (requiredApp) localStorage.siteName = siteNameInQuery;

export const CURRENT_SITE =
  requiredApp ||
  SITES.find((o) => o.hostnames?.find((h) => h === window.location.hostname)) ||
  SITES.find((o) => o.pathnames?.find((h) => window.location.pathname.startsWith(h))) ||
  SITES.find(
    (o) => o.slug === (isDev() ? DEVELOPING_LOCALHOST_SITE : DEFAULT_SITE)
  );
